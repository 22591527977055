const {UserFlags} = require('./bitsets');

const experiments = {
    '2022_01_01_Conditional_Logos': {
        name: 'Conditional Logos',
        possibleValues: ['default', 'pride'],
        getValue: (user, identity) => {
            const today = new Date();

            if (today.getMonth() === 5) { // 0-indexed months, very intuitive
                return 'pride';
            }

            return 'default';
        }
    },
    '2022_10_13_2FA': {
        name: 'Two Factor Authentication',
        possibleValues: [true, false],
        getValue: (user, identity) => {
            if (!user) return false;

            // Only expose to staff for now
            return (user.flags & UserFlags.Staff) !== 0;
        }
    },
    '2022_10_19_Premium_Videos': {
        name: 'Premium Videos',
        possibleValues: [true, false],
        getValue: (user, identity) => {
            if (!user) return false;

            // Only expose to staff and CH premium users for now
            return (user.flags & UserFlags.Staff) !== 0 || (user.flags & UserFlags.Premium) !== 0;
        }
    },
    '2022_10_28_Watch_Together': {
        name: 'Watch Together',
        possibleValues: [true, false],
        getValue: (user, identity) => {
            if (!user) return false;

            // Only expose to staff for now
            return (user.flags & UserFlags.Staff) !== 0;
        }
    },
    '2022_12_30_Adsense': {
        name: 'Adsense Chance',
        possibleValues: [0, 25, 50, 75, 100],
        getValue: (user, identity) => {
            return 50;
        }
    },
    '2023_04_14_Moneytizer': {
        name: 'Moneytizer',
        possibleValues: [true, false],
        getValue: (user, identity) => {
            return false; // Experiment disabled
            const CHANCE = 2; // 1 in X

            if (user) return getBucket(user, CHANCE) === 0;
            return identity.hashNumerical % CHANCE === 0;
        }
    },
    '2024_01_03_i18n': {
        name: 'i18n',
        possibleValues: [true, false],
        getValue(user, identity) {
            return true;
        }
    },
    '2024_01_10_billboard.zone': {
        name: 'billboard.zone Chance (post adsense)',
        possibleValues: [0, 25, 33, 50, 75, 100],
        getValue: (user, identity) => {
            return 0; // Experiment disabled
            return 50;
        }
    },
};

for (const experimentName of Object.keys(experiments)) {
    const experiment = experiments[experimentName];
    const originalGetValue = experiment.getValue;
    experiment.getValue = (...args) => {
        if (experiment.override !== undefined) {
            return experiment.override;
        }

        return originalGetValue(...args);
    };
}

module.exports = experiments;

function getBucket(user, bucketSize) {
    // grab the last sequence of chars at the end of the uuid
    let group = user.id.split('-').pop();
    let num = parseInt(group, 16);

    return num % bucketSize;
}
