import {I18nText} from '../i18n/I18nText';
import experiments from '../shared/experiments';
import {useSelector} from 'react-redux';
import {LanguagePicker} from '../components/LanguagePicker';

export default function NetworkBar() {
    const user = useSelector((state) => state.user);

    return <div className="networkbar background-black relative text-center hidden sm:block">
        <a className="networkbar-link" href="https://discord.gg/b3ZCsWkE2G" target="_blank" rel="noreferrer"><I18nText
            id="social.discord"/></a>
        <a className="networkbar-link" href="https://instagram.com/cornhub.website" target="_blank"
           rel="noreferrer"><I18nText
            id="social.instagram"/></a>
        <a className="networkbar-link" href="https://mastodon.federated.club/@cornhub" target="_blank"
           rel="noreferrer"><I18nText
            id="social.mastodon"/></a>
        <a className="networkbar-link" href="https://tiktok.com/@cornhub.website" target="_blank"
           rel="noreferrer"><I18nText
            id="social.tiktok"/></a>
        <a className="networkbar-link" href="https://twitter.com/cornhubwebsite" target="_blank"
           rel="noreferrer"><I18nText
            id="social.twitter"/></a>
        <a className="networkbar-link" href="https://youtube.com/@cornhub.website" target="_blank"
           rel="noreferrer"><I18nText
            id="social.youtube"/></a>
        {experiments['2024_01_03_i18n'].getValue(user) ? <>
            |
            <LanguagePicker className="locale-link ml-4 bg-transparent"/>
        </> : null}
    </div>;
}
